<template>
  <base-form-item
      :field="field"
      :schemaVersion="schemaVersion"
  >
    <div
        class="el-form-text"
        v-if="value && dictionaries && dictionaries[dictionaryName]"
    >
      <span>{{ field.front_properties.translate ? $t(`system.${getValue.toLowerCase()}`) : getValue }}</span>
    </div>
    <div
        class="el-form-text"
        v-else
    >
      <span class="no-date-title">{{ $t('system.no_data') }}</span>
    </div>
  </base-form-item>
  <!--    <div>-->
  <!--        <el-form-item :label-width="field.label && field.label.labelWidth ? field.label.labelWidth + 'px' : ''"-->
  <!--                      :size="field.meta && field.meta.size ? field.meta.size : ''"-->
  <!--                      :class="[field.label && field.label.labelPosition ? 'el-form&#45;&#45;labelPosition-' + field.label.labelPosition : 'el-form&#45;&#45;labelPosition-top']">-->
  <!--            <template slot="label">-->
  <!--                <Tooltip v-if="field.label && field.label.labelTooltip"-->
  <!--                         :labelTooltip="field.label.labelTooltip"-->
  <!--                         :content="field.label && field.label.name ? $t(field.label.name) : ''"></Tooltip>-->
  <!--                <span v-else class="labelWrap">{{field.label && field.label.name ? $t(field.label.name) : ''}}</span>-->
  <!--            </template>-->
  <!--            <div v-if="value && dictionaries && dictionaries[field.dictionary]"-->
  <!--                 class="el-form-text">-->
  <!--                <span>{{ getValue }}</span>-->
  <!--            </div>-->
  <!--            <div v-else-->
  <!--                 class="el-form-text">-->
  <!--                <span class="no-date-title">{{ $t('system.no_data') }}</span>-->
  <!--            </div>-->
  <!--        </el-form-item>-->
  <!--    </div>-->
</template>

<script>
import BaseFormItem from '../../UI/FormItem.vue';
import abstractForm from '../../mixin/index';

export default {
  mixins: [abstractForm],
  name: 'Index',
  components: {BaseFormItem},
  computed: {
    getValue() {
      const currentItems = this.dictionaries[this.dictionaryName].filter(item => item.id === parseInt(this.value));
      return currentItems.map((item, index) => {
        if (index === currentItems.length - 1) {
          return item.name;
        }
        return `${item.name}, `;
      });
    },
  },
};
</script>
<style
    lang="scss"
    rel="stylesheet/scss"
    scoped
>

</style>
